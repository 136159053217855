import { Box } from '@otovo/rainbow';
import LegalLinks from 'otovoweb/src/apps/Navigation/Footer/LegalLinks';

const Footer = () => {
  return (
    <Box as="footer" backgroundColor="lilac_100">
      <Box maxWidth="18" mx="auto" px={{ s: '6', s3: '8', l2: '10' }} py="6">
        <LegalLinks />
      </Box>
    </Box>
  );
};

export default Footer;
