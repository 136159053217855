import { Box } from '@otovo/rainbow';
import { useGlobalConfig } from '../../../otovoweb/src/configContext';

function getLogoSize(buSlug: string) {
  switch (buSlug) {
    case 'energygo-es':
      return {
        width: '145px',
        height: 'auto',
      };
    case 'imaginaenergia-es':
      return {
        width: '66px',
        height: 'auto',
      };
    case 'lucera-es':
      return {
        width: 'auto',
        maxHeight: '32px',
      };
    case 'masmovil-es':
      return {
        width: 'auto',
        maxHeight: '32px',
      };
    default:
      return {
        width: 'auto',
        height: '6',
      };
  }
}

const BusinessUnitLogo = () => {
  const { BU_CONFIG } = useGlobalConfig();
  const size = getLogoSize(BU_CONFIG.slug);
  return (
    <Box
      as="img"
      src={BU_CONFIG.logo}
      alt={`${BU_CONFIG.company_name} logo`}
      {...size}
    />
  );
};

export default BusinessUnitLogo;
