import { Box } from '@otovo/rainbow';
import { useGlobalConfig } from 'otovoweb/src/configContext';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import BusinessUnitLogo from './BusinessUnitLogo/BusinessUnitLogo';
import { getStorefrontUrlWithPartners } from './lib/storefrontUrls';
import { Cloud$BusinessUnitConfig } from './types/cloudApi';

type NavbarProps = {
  children?: React.ReactNode;
  homePageUrl?: string;
};

const LanguageSwitcher = dynamic(
  () => import('otovoweb/src/apps/Navigation/LanguageSwitcher'),
  { ssr: false },
);

const Navbar = ({ children, homePageUrl }: NavbarProps) => {
  const { STOREFRONT_URL, BU_CONFIG } = useGlobalConfig();

  const isMultiLocaleBusinessUnit = (
    businessUnit: Cloud$BusinessUnitConfig,
  ) => {
    return businessUnit.supported_locales.length > 1;
  };

  return (
    <Box
      // Don't change this ID - useElementHeight relies on it
      id="otovoNavBar"
      as="nav"
      backgroundColor="white"
      borderBottom="1px solid"
      borderColor="grey_10"
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr"
      alignItems="center"
    >
      <Box>{isMultiLocaleBusinessUnit(BU_CONFIG) && <LanguageSwitcher />}</Box>
      <Box pb="3" pt="4" textAlign="center">
        <Link
          className="flex justify-center"
          href={
            homePageUrl ||
            getStorefrontUrlWithPartners(BU_CONFIG.slug, STOREFRONT_URL)
          }
        >
          <BusinessUnitLogo />
        </Link>
      </Box>
      <Box display="flex" justifyContent="flex-end" pr="3" alignItems="center">
        {children}
      </Box>
    </Box>
  );
};

export default Navbar;
