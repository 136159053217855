import { useGlobalConfig } from '../configContext';

type Props = {
  includeUnits?: string[];
  excludeUnits?: string[];
  onlyCentral?: boolean; // Only show this for central units
  children: React.ReactNode;
};

const BusinessUnitWrapper = ({
  children,
  includeUnits,
  excludeUnits,
  onlyCentral,
}: Props) => {
  const { BU_CONFIG } = useGlobalConfig();
  if (!includeUnits && !excludeUnits && !onlyCentral) {
    return null;
  }
  if (
    onlyCentral &&
    !['OWN_AND_OPERATE', 'WHITE_LABEL'].includes(BU_CONFIG.type)
  ) {
    return null;
  }
  if (!!excludeUnits && excludeUnits.includes(BU_CONFIG.slug)) {
    return null;
  }
  if (!!includeUnits && !includeUnits.includes(BU_CONFIG.slug)) {
    return null;
  }

  return <>{children}</>;
};

export default BusinessUnitWrapper;
