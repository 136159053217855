import { Cloud$Market } from '@otovo/shared/types/cloudApi';
import { useGlobalConfig } from '../configContext';

type Props = {
  markets?: Array<Cloud$Market>;
  exclude?: Array<Cloud$Market>;
  children: JSX.Element;
};

const MarketWrapper = ({ children, markets, exclude }: Props) => {
  const { BU_CONFIG } = useGlobalConfig();
  if (!markets && !exclude) {
    return null;
  }
  if (!!exclude && exclude.includes(BU_CONFIG.market)) {
    return null;
  }
  if (!!markets && !markets.includes(BU_CONFIG.market)) {
    return null;
  }
  return children;
};

export default MarketWrapper;
